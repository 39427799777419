<template>
	<div class="user">
		<search-header :isSnShow="false"></search-header>
		<div class="container pt-3 pb-3 p-0">
			<div class="row p-0">
				<div class="col-2">
					<user-left :currentActive="0"></user-left>
				</div>
				<div class="col-10">
					<div class="row ml-2">
						<div class="col-7 p-0">
							<div class="bg-white rounded clearfix">
								<div class="float-left p-4">
									<div class="row">
										<div class="col-2 p-0 pl-2">
											<img class="rounded-circle width-60" src="~@/assets/img/headpic.png">
										</div>
										<div class="col-10 p-0 pl-3">
											<div class="user-content clearfix">
												<span class="user-name text-overflow float-left">
													t_7256，欢迎您
												</span>
												<a href="javascript:;"
													class="user-dashi-btn no-dashi text-center float-left">
													<i class="custom-icon icon-vip f-18"></i>
													<div class="dashi-tips">开通vip，享受更优展位，更快成交！</div>
												</a>
											</div>
											<div class="user-company">
												<a href="javascript:;" class="company-name J-cmpyinfo-btn">
													<span class="text edit-company click-show-model add-bg-color">
														完成实名认证，享受更优服务，去认证<i></i>
													</span>
												</a>
											</div>
										</div>
									</div>
									<div class="mt-4">
										<span class="text-secondary">账户余额：</span>
										<span class="text-danger">0元</span>
										<span class="text-secondary ml-3">提现金额：</span>
										<span class="text-theme">0元</span>
									</div>
								</div>
								<div class="float-right user-right">
									<div class="noCompnay-ctn company-ctn">
										<div class="join-title">诚邀您加入土地代理</div>
										<div class="join-text">
											仅剩<span class="proportion">70</span>个代理商名额，平台资源多，运营支持全，让您轻松创业挣钱。
										</div>
										<div class="join-bottom">
											<a class="btn" href="javascript:;"><span>了解详情</span> <i
													class="q-icon q-icon-next"></i></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col p-0 pl-3">
							<div class="bg-white rounded">
								<div class="p-2 pl-3 border-bottom">
									<span>我的数据</span>
								</div>
								<div class="data-center">
									<div class="mr-3 ml-3 pt-2 d-flex justify-content-between align-items-center">
										<span class="text-secondary f-14">我发布的土地</span>
										<b-badge variant="success" pill>3</b-badge>
									</div>
									<div class="mr-3 ml-3 pt-1 d-flex justify-content-between align-items-center">
										<span class="text-secondary f-14">我登记的需求</span>
										<b-badge variant="success" pill>2</b-badge>
									</div>
									<div class="mr-3 ml-3 pt-1 d-flex justify-content-between align-items-center">
										<span class="text-secondary f-14">我收藏的信息</span>
										<b-badge variant="success" pill>20</b-badge>
									</div>
									<div class="mr-3 ml-3 pt-1 d-flex justify-content-between align-items-center">
										<span class="text-secondary f-14">我发布的提问</span>
										<b-badge variant="success" pill>1</b-badge>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 p-0 mt-3">
							<div class="bg-white rounded">
								<div class="p-3 border-bottom">
									<span>我交易中的订单</span>
								</div>
								<div class="order-center">
									<div class="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
										<div class="text-gray-777">暂无订单，找土地流转您只需要</div>
										<div class="ml-3">
											<b-button variant="success" >发布需求</b-button>
										</div>
										
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 p-0 mt-3">
							<div class="bg-white rounded">
								<div class="p-3 border-bottom">
									<span>土地工具</span>
								</div>
								<div class="app-center">
									<h6 class="m-4">土地流转</h6>
									<div class="row ml-4 f-12 text-gray-777 clearfix">
										<router-link to="/farmland" target="_blank" class="float-left d-flex flex-column justify-content-center align-items-center">
											<div><img src="~@/assets/icon/znyd.png"/></div>
											<div class="mt-2">找农用地</div>
										</router-link>
										<router-link to="/farmhouse" target="_blank" class="float-left ml-5 d-flex flex-column justify-content-center align-items-center">
											<img src="~@/assets/icon/zczd.png"/>
											<div class="mt-2">找农房</div>
										</router-link>
										<router-link to="/finance" target="_blank" class="float-left ml-5 d-flex flex-column justify-content-center align-items-center">
											<img src="~@/assets/icon/jrfw.png"/>
											<div class="mt-2">金融服务</div>
										</router-link>
										<router-link to="/user/land/add" target="_blank" class="float-left ml-5 d-flex flex-column justify-content-center align-items-center">
											<img src="~@/assets/icon/fbtd.png"/>
											<div class="mt-2">发布土地</div>
										</router-link>
										<router-link to="/user/needs/add" target="_blank" class="float-left ml-5 d-flex flex-column justify-content-center align-items-center">
											<img src="~@/assets/icon/qzqg.png"/>
											<div class="mt-2">求租求购</div>
										</router-link>
										<router-link to="/servicecentre/index" target="_blank" class="float-left ml-5 d-flex flex-column justify-content-center align-items-center">
											<img src="~@/assets/icon/sqhz.png"/>
											<div class="mt-2">申请合作</div>
										</router-link>
										<router-link to="/estimate" target="_blank" class="float-left ml-5 d-flex flex-column justify-content-center align-items-center">
											<img src="~@/assets/icon/tdpg.png"/>
											<div class="mt-2">土地评估</div>
										</router-link>
									</div>
									<h6 class="m-4">土地测绘</h6>
									<div class="row ml-4 f-12 text-gray-777 clearfix">
										<router-link to="/needs/makemaps?name=航飞测量" target="_blank" class="float-left d-flex flex-column justify-content-center align-items-center">
											<div><img src="~@/assets/icon/hfch.png"/></div>
											<div class="mt-2">航飞测量</div>
										</router-link>
										<router-link to="/needs/makemaps?name=土地平整" target="_blank" class="float-left ml-5 d-flex flex-column justify-content-center align-items-center">
											<img src="~@/assets/icon/tdpz.png"/>
											<div class="mt-2">土地平整</div>
										</router-link>
										<router-link to="/needs/makemaps?name=飞防" target="_blank" class="float-left ml-5 d-flex flex-column justify-content-center align-items-center">
											<img src="~@/assets/icon/ff.png"/>
											<div class="mt-2">飞防</div>
										</router-link>
										<router-link to="/needs/makemaps?name=松害线虫病防控" target="_blank" class="float-left ml-5 d-flex flex-column justify-content-center align-items-center">
											<img src="~@/assets/icon/shxcbfk.png"/>
											<div class="mt-2">松害线虫病防控</div>
										</router-link>
										<router-link to="/needs/makemaps?name=高标准农田" target="_blank" class="float-left ml-5 d-flex flex-column justify-content-center align-items-center">
											<img src="~@/assets/icon/gbznt.png"/>
											<div class="mt-2">高标准农田</div>
										</router-link>
										<router-link to="/needs/makemaps?name=其他测绘项目" target="_blank" class="float-left ml-5 d-flex flex-column justify-content-center align-items-center">
											<img src="~@/assets/icon/qtch.png"/>
											<div class="mt-2">其他测绘项目</div>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<map-footer :isMapShow="false"></map-footer>
	</div>
</template>

<script>
	export default {
		created() {

		}
	}
</script>

<style scoped>
	@import url("~@/assets/css/user.css");
</style>
